import React from 'react';
import Logo from './asset/logoMain.png';
import './styles.css'; // Import custom CSS

const Footer = () => {
  return (
    <footer className="footer-container bg-darkblue text-white py-8">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Contact Us Column */}
          <div className="column animate-fade-in">
            <h2 className="text-lg font-semibold">Contact Us</h2>
            <p>Email: support@conversai.com</p>
            <p>Phone: 08162099082</p>
            <p>
              Address:
              <br /> 9/11 Atundaolu, Alafia Estate.
              <br /> Ogba Lagos State
            </p>
          </div>

          {/* Quick Links Column */}
          <div className="column animate-fade-in delay-100">
            <h2 className="text-lg font-semibold">Quick Links</h2>
            <ul>
              <li>
                <a
                  href="#"
                  className="hover:underline hover:text-blue-300 transition duration-300">
                  Home
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="hover:underline hover:text-blue-300 transition duration-300">
                  About Us
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="hover:underline hover:text-blue-300 transition duration-300">
                  Case Studies
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="hover:underline hover:text-blue-300 transition duration-300">
                  Blog
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="hover:underline hover:text-blue-300 transition duration-300">
                  Contact
                </a>
              </li>
            </ul>
          </div>

          {/* Company Column */}
          <div className="column animate-fade-in delay-200">
            <h2 className="text-lg font-semibold">Company</h2>
            <ul>
              <li>
                <a
                  href="#"
                  className="hover:underline hover:text-blue-300 transition duration-300">
                  About us
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="hover:underline hover:text-blue-300 transition duration-300">
                  Careers
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="hover:underline hover:text-blue-300 transition duration-300">
                  Contact us
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="hover:underline hover:text-blue-300 transition duration-300">
                  Lift Media
                </a>
              </li>
            </ul>
          </div>

          {/* Subscribe Column */}
          <div className="column animate-fade-in delay-300">
            <h2 className="text-lg font-semibold">Subscribe</h2>
            <form className="flex items-center">
              <input
                type="email"
                placeholder="Email address"
                className="p-2 rounded-l-md border border-gray-300"
              />
              <button
                type="submit"
                className="subscribe-button rounded-r-md bg-blue-500 text-white p-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-10 h-10">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                </svg>
              </button>
            </form>
            <p className="mt-2">
              Subscribe to our newsletter to get the latest updates and insights
              from ConversAI.
            </p>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="footer-bottom flex flex-col md:flex-row justify-between items-center pt-4 border-t border-gray-700 mt-8 animate-slide-in">
          <div>
            <img src={Logo} alt="Company Logo" className="h-10" />
          </div>
          <div className="mt-4 md:mt-0">
            <a
              href="#"
              className="mx-2 hover:underline hover:text-blue-300 transition duration-300">
              Terms
            </a>
            <a
              href="#"
              className="mx-2 hover:underline hover:text-blue-300 transition duration-300">
              Privacy
            </a>
            <a
              href="#"
              className="mx-2 hover:underline hover:text-blue-300 transition duration-300">
              Cookies
            </a>
          </div>
          <div className="social-icons flex mt-4 md:mt-0">
            <a
              href="#"
              className="mx-2 hover:text-blue-300 transition duration-300">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                className="w-5 h-5"
                viewBox="0 0 16 16">
                <path d="M8 0C3.582 0 0 3.582 0 8c0 3.837 2.719 7.03 6.26 7.807v-5.52H4.433v-2.286H6.26V5.733c0-1.8 1.084-2.79 2.742-2.79.791 0 1.615.14 1.615.14v1.771H9.972c-1.01 0-1.325.632-1.325 1.274v1.534h2.318l-.37 2.286H8.647v5.52C12.281 15.03 15 11.837 15 8c0-4.418-3.582-8-7-8z"></path>
              </svg>
            </a>
            <a
              href="#"
              className="mx-2 hover:text-blue-300 transition duration-300">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                className="w-5 h-5"
                viewBox="0 0 16 16">
                <path d="M16 1.538c-.588.261-1.22.437-1.882.516.678-.407 1.197-1.051 1.444-1.821-.633.375-1.334.649-2.084.799-.599-.639-1.453-1.037-2.396-1.037-1.815 0-3.288 1.473-3.288 3.287 0 .258.03.51.085.75C3.728 4.243 1.97 3.161.794 1.497.593 1.935.497 2.445.497 2.975c0 1.14.58 2.145 1.46 2.733-.539-.017-1.045-.166-1.489-.413v.041c0 1.593 1.134 2.922 2.642 3.225-.277.076-.57.116-.87.116-.213 0-.42-.02-.621-.058.42 1.31 1.64 2.261 3.087 2.288-1.13.886-2.55 1.412-4.098 1.412-.267 0-.532-.016-.792-.045 1.46.933 3.19 1.478 5.053 1.478 6.058 0 9.367-5.017 9.367-9.367 0-.142-.003-.282-.01-.421.643-.465 1.197-1.044 1.637-1.706z"></path>
              </svg>
            </a>
            <a
              href="#"
              className="mx-2 hover:text-blue-300 transition duration-300">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                className="w-5 h-5"
                viewBox="0 0 16 16">
                <path d="M12.934 7.23c.008.11.008.22.008.33 0 3.374-2.568 7.266-7.266 7.266-1.446 0-2.79-.426-3.925-1.157.204.022.392.03.606.03 1.195 0 2.296-.39 3.166-1.052-1.12-.022-2.066-.763-2.39-1.78.157.03.314.044.48.044.23 0 .454-.03.667-.083-1.17-.236-2.04-1.26-2.04-2.494v-.03c.344.192.743.308 1.166.322a2.577 2.577 0 01-.796-3.44c1.308 1.604 3.267 2.658 5.48 2.77-.045-.193-.068-.398-.068-.602 0-1.473 1.197-2.67 2.67-2.67.768 0 1.46.322 1.947.841.608-.12 1.17-.34 1.68-.64-.2.615-.623 1.13-1.17 1.46.54-.06 1.04-.208 1.51-.418-.352.513-.798.96-1.31 1.318z"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
