import React from 'react';
import rectangle0 from './asset/Rectangle0.png';
const BenefitCard = () => {
  return (
    <div className="benefit-card-container">
      <div className="benefit-card">
        <div className="image-container">
          <img src={rectangle0} alt="Save Time" />
        </div>
        <div className="text-container">
          <h2 className="text-bold">Save Time</h2>
          <p className='justify'>
            Automate repetitive tasks, allowing your team to focus on strategic
            activities.
          </p>
        </div>
      </div>
    </div>
  );
};
export default BenefitCard

