import React, { useState, useEffect } from 'react';
import { FaDiscord } from 'react-icons/fa';
import { IoSettingsSharp } from 'react-icons/io5';
import { BiChat } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { FiMenu, FiX } from 'react-icons/fi';
import { GoogleGenerativeAI } from '@google/generative-ai';

const ChatBot = () => {
  const [messages, setMessages] = useState([]);
  const [genAI, setGenAI] = useState(null);
  const [chat, setChat] = useState(null);
  const [input, setInput] = useState('');
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [showPrompts, setShowPrompts] = useState(true);

  useEffect(() => {
    const initGenerativeAI = async () => {
      try {
        const apiKey = process.env.REACT_APP_API_KEY;
        if (!apiKey) {
          throw new Error(
            'API key not found. Please set REACT_APP_API_KEY in your environment variables.'
          );
        }
        const ai = new GoogleGenerativeAI(apiKey);
        setGenAI(ai);
      } catch (error) {
        console.error('Error initializing Generative AI:', error.message);
      }
    };

    initGenerativeAI();
  }, []);

  useEffect(() => {
    if (genAI) {
      const initChat = async () => {
        try {
          const model = genAI.getGenerativeModel({ model: 'gemini-1.5-flash' });
          const newChat = model.startChat({
            history: [],
            generationConfig: { maxOutputTokens: 100 },
          });
          setChat(newChat);
        } catch (error) {
          console.error('Error initializing chat:', error.message);
        }
      };

      initChat();
    }
  }, [genAI]);

  const handleSend = async () => {
    if (input.trim() === '') return;

    const userMessage = { text: input, sender: 'user' };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setInput('');
    setIsLoading(true);

    try {
      const result = await chat.sendMessage(input);
      const response = await result.response;
      const text = await response.text();

      const aiMessage = { text, sender: 'ai' };
      setMessages((prevMessages) => [...prevMessages, aiMessage]);
    } catch (error) {
      console.error('Error generating content:', error);
      const errorMessage = {
        text: 'Sorry, something went wrong. Please try again later.',
        sender: 'ai',
      };
      setMessages((prevMessages) => [...prevMessages, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const handlePromptClick = async (message) => {
    setShowPrompts(false); // Hide the prompts after clicking
    await sendMessage(message);
  };

  const sendMessage = async (message) => {
    if (!chat) return;

    const userMessage = { text: message, sender: 'user' };
    setMessages([userMessage]); // Clear existing messages and show the prompt message
    setIsLoading(true);

    try {
      const result = await chat.sendMessage(message);
      const response = await result.response;
      const text = await response.text();

      const aiMessage = { text, sender: 'ai' };
      setMessages((prevMessages) => [...prevMessages, aiMessage]);
    } catch (error) {
      console.error('Error generating content:', error);
      const errorMessage = {
        text: 'Sorry, something went wrong. Please try again later.',
        sender: 'ai',
      };
      setMessages((prevMessages) => [...prevMessages, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  const clearConversation = () => {
    setMessages([]);
    setShowSidePanel(false); // Close sidebar when clearing conversation
  };

  const toggleSidePanel = () => {
    setShowSidePanel(!showSidePanel);
  };

  const closeSidebar = () => {
    setShowSidePanel(false);
  };

  return (
    <div
      className={`flex min-h-screen ${
        isDarkMode ? 'bg-gray-800 text-white' : 'bg-gray-100 text-black'
      }`}>
      {/* Sidebar */}
      <button
        onClick={toggleSidePanel}
        className={`fixed top-4 left-4 p-3 ${
          isDarkMode ? 'bg-gray-700 text-white' : 'bg-gray-300 text-black'
        } rounded-full z-10`}>
        {showSidePanel ? (
          <FiX className="text-lg" />
        ) : (
          <FiMenu className="text-lg" />
        )}
      </button>

      <div
        className={`fixed inset-0 bg-gray-900 md:bg-gray-800 bg-opacity-75 md:bg-opacity-0 transform ${
          showSidePanel ? 'translate-x-0' : '-translate-x-full'
        } transition-transform duration-300 ease-in-out z-20`}
        onClick={closeSidebar}>
        <div
          className={`w-64 md:w-1/4 ${
            isDarkMode ? 'bg-gray-900' : 'bg-gray-200'
          } p-4 flex flex-col justify-between h-full`}>
          <div>
            <div className="flex items-center mb-6">
              <FaDiscord className="text-2xl mr-3" />
              <h1 className="text-2xl font-bold">ConversAI</h1>
            </div>

            <div className="flex flex-col space-y-2">
              <button
                className={`flex items-center p-5  mb-96 ${
                  isDarkMode
                    ? 'bg-gray-700 hover:bg-gray-600'
                    : 'bg-gray-300 hover:bg-gray-400'
                } rounded-lg`}
                onClick={clearConversation}>
                <BiChat className="text-lg mr-2" />
                <span className="truncate">New chat</span>
              </button>
              <hr className="border-gray-500 my-3" />
              <button
                className={`flex items-center p-5  ${
                  isDarkMode
                    ? 'bg-gray-700 hover:bg-gray-600'
                    : 'bg-gray-300 hover:bg-gray-400'
                } rounded-lg`}
                onClick={clearConversation}>
                <IoSettingsSharp className="text-lg mr-2" />
                <span className="truncate">Clear conversations</span>
              </button>
              <button
                className={`flex items-center p-5 ${
                  isDarkMode
                    ? 'bg-gray-700 hover:bg-gray-600'
                    : 'bg-gray-300 hover:bg-gray-400'
                } rounded-lg`}
                onClick={toggleDarkMode}>
                <IoSettingsSharp className="text-lg mr-2" />
                <span className="truncate">Light mode</span>
              </button>
              <a
                href="https://discord.gg/your-discord-link"
                target="_blank"
                rel="noopener noreferrer"
                className={`flex items-center p-5 ${
                  isDarkMode
                    ? 'bg-gray-700 hover:bg-gray-600'
                    : 'bg-gray-300 hover:bg-gray-400'
                } rounded-lg`}>
                <FaDiscord className="text-lg mr-2" />
                <span className="truncate">ConversAI Discord</span>
              </a>
              <button
                className={`flex items-center p-5 ${
                  isDarkMode
                    ? 'bg-gray-700 hover:bg-gray-600'
                    : 'bg-gray-300 hover:bg-gray-400'
                } rounded-lg`}>
                <IoSettingsSharp className="text-lg mr-2" />
                <span className="truncate">Updates & FAQ</span>
              </button>
              <Link
                to="/"
                className={`flex items-center p-5 ${
                  isDarkMode
                    ? 'bg-gray-700 hover:bg-gray-600'
                    : 'bg-gray-300 hover:bg-gray-400'
                } rounded-lg`}>
                <IoSettingsSharp className="text-lg mr-2" />
                <span className="truncate">Log out</span>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Main Chat Area */}
      <div className="flex-1 flex flex-col p-4 ml-0 md:ml-64 relative">
        {showPrompts && (
          <div className="absolute inset-0 flex flex-col items-center justify-center z-30 space-y-4">
            <Link
              onClick={() => handlePromptClick('Generate Social Copies')}
              className="bg-purple-800 text-white px-4 py-4 rounded-lg mb-2 hover:bg-purple-900">
              Generate Social Copies
            </Link>
            <Link
              onClick={() => handlePromptClick('Generate Social Copies')}
              className="bg-purple-800 text-white px-4 py-4 rounded-lg mb-2 hover:bg-purple-900">
              Get engaging social media posts in seconds
            </Link>

            <Link
              onClick={() => handlePromptClick('Generate Social Copies')}
              className="bg-purple-800 text-white px-4 py-4 rounded-lg mb-2 hover:bg-purple-900">
              Content Generation
            </Link>

            <Link
              onClick={() => handlePromptClick('Generate Social Copies')}
              className="bg-purple-800 text-white px-4 py-4 rounded-lg mb-2 hover:bg-purple-900">
              Conversation History
            </Link>

            <Link
              onClick={() => handlePromptClick('Generate Social Copies')}
              className="bg-purple-800 text-white px-4 py-4 rounded-lg mb-2 hover:bg-purple-900">
              Text Summarization
            </Link>

            <Link
              onClick={() => handlePromptClick('Generate Social Copies')}
              className="bg-purple-800 text-white px-4 py-4 rounded-lg mb-2 hover:bg-purple-900">
              Language Translation
            </Link>

            <button
              onClick={() => handlePromptClick('Create Landing Page Copy')}
              className="bg-purple-800 text-white px-4 py-4 rounded-lg mb-2 hover:bg-purple-900">
              Create Landing Page Copy
            </button>
            <button
              onClick={() => handlePromptClick('Get Feedback')}
              className="bg-purple-800 text-white px-4 py-2 rounded-lg mb-2 hover:bg-blue-600">
              Get Feedback
            </button>
            <button
              onClick={() => handlePromptClick('Run A/B Testing')}
              className="bg-purple-800 text-white px-4 py-4 rounded-lg mb-2 hover:bg-blue-600">
              Run A/B Testing
            </button>
          </div>
        )}

        <div className="flex-1 overflow-y-auto mb-4 relative">
          {messages.map((message, index) => (
            <div
              key={index}
              className={`p-4 mb-2 rounded ${
                message.sender === 'user'
                  ? `${
                      isDarkMode ? 'bg-purple-800' : 'bg-blue-300'
                    } self-end text-right`
                  : `${
                      isDarkMode ? 'bg-gray-700' : 'bg-gray-300'
                    } self-start text-left`
              }`}>
              {message.text}
            </div>
          ))}
          {isLoading && (
            <div className="absolute inset-0 flex items-center justify-center bg-opacity-75 bg-gray-900 z-10">
              <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
            </div>
          )}
        </div>

        <div className="flex">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && handleSend()}
            className={`flex-1 p-2 ${
              isDarkMode ? 'bg-gray-900' : 'bg-gray-200'
            } rounded-l`}
            placeholder="Type your message..."
          />
          <button
            onClick={handleSend}
            className={`p-3 ${
              isDarkMode ? 'bg-purple-800' : 'bg-purple-800'
            } rounded-r text-white hover:bg-purple-800`}>
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatBot;


