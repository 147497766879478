import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Autoplay } from 'swiper/modules';

// Replace with your own images or paths
import img1 from './asset/Paul.JPG';
import img2 from './asset/knowledge.png';
import img3 from './asset/monitoring_3703299.png';

const testimonials = [
  {
    text: 'Convers AI has drastically improved our customer engagement and sales. The AI integration is seamless and intuitive.',
    name: 'Big Paul',
    position: 'CEO of The LON Store',
    image: img1,
  },
  {
    text: "This AI tool has revolutionized the way we handle customer inquiries. It's fast, reliable, and very easy to use.",
    name: 'Jane Doe',
    position: 'Marketing Manager',
    image: img2,
  },
  {
    text: 'Our support team productivity has increased dramatically since we started using Convers AI. Highly recommended!',
    name: 'John Smith',
    position: 'Support Lead',
    image: img3,
  },
  // Add more testimonials as needed
];

const TestimonialSwiper = () => {
  return (
    <div className="testimonial-container">
      <h1 className="text-bold">Testimonial</h1>
      <Swiper
        modules={[Pagination, Autoplay]}
        spaceBetween={50}
        slidesPerView={1}
        autoplay={{ delay: 3000 }}
        pagination={{ clickable: true }}>
        {testimonials.map((testimonial, index) => (
          <SwiperSlide key={index}>
            <div className="testimonial-card">
              <div className="text-container">
                <p>{testimonial.text}</p>
                <h3>{testimonial.name}</h3>
                <p>{testimonial.position}</p>
              </div>
              <div className="image-container">
                <img src={testimonial.image} alt={testimonial.name} />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default TestimonialSwiper;
