import React from 'react';
import { Routes, Route } from 'react-router-dom';
import LandingPage from './Component/Landingpage';
import Register from './Component/Register';
import SignIn from './Component/Signup';
import Dashboard from './Component/Dashboard';
import ChatBot from './Component/chatDashboard/chatbot';

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/register" element={<Register />} />
      <Route path="/signup" element={<SignIn />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/ChatBot" element={<ChatBot />} />
    </Routes>
  );
};

export default App;
