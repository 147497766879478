import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from '@headlessui/react';
import { FiMenu, FiX } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import Logo from './asset/logoMain.png';
import Modal from './Modal'; // Import the Modal component
import yourVideo from './asset/video.mp4'; // Import the video file
import '../index.css';

const navigation = [
  { name: 'Features', href: '#features' },
  { name: 'Integrations', href: '#integrations' },
  { name: 'Pricing', href: '#pricing' },
];

const Header = () => {
  const [showVideo, setShowVideo] = useState(false);

  const handleLiveChatClick = (e) => {
    e.preventDefault();
    setShowVideo(true);
  };

  const handleCloseVideo = () => {
    setShowVideo(false);
  };

  return (
    <header className="fixed top-0 left-0 right-0 bg-white z-10 shadow-md w-full">
      <div className="container mx-auto px-4 md:px-6 py-4 md:py-6">
        <Popover>
          {({ open }) => (
            <>
              <div className="relative flex lg:ml-72 justify-between items-center md:flex bg-white shadow-md rounded-xl p-1 space-x-6">
                <Link to="/" className="flex items-center">
                  <img
                    className="h-16 w-auto sm:h-14 rounded-lg drop-shadow-lg"
                    src={Logo}
                    alt="Logo"
                  />
                  <span className="ml-6 text-3xl font-bold text-purple-950">
                    CONVERSE AI
                  </span>
                </Link>
                <div className="hidden md:flex ml-12 flex-grow justify-center font-bold space-x-8">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="font-medium text-lg text-black hover:text-gray-950">
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="hidden md:flex lg:ml-14 bg-white shadow-md rounded-lg p-2 space-x-4">
                  <Link
                    to="/"
                    className="flex items-center justify-center px-3 py-2 sm:px-4 sm:py-3 md:px-5 md:py-3 border border-gray-950 text-base sm:text-lg font-bold rounded-lg text-black hover:bg-gray-800 hover:text-white"
                    onClick={handleLiveChatClick}>
                    <FontAwesomeIcon icon={faPlay} className="mr-2" />
                    Live Demo
                  </Link>
                  <Link
                    to="/register"
                    className="flex items-center justify-center px-3 py-2 sm:px-4 sm:py-3 md:px-5 md:py-3 border border-gray-950 text-base sm:text-lg font-bold rounded-lg text-black hover:bg-gray-800 hover:text-white">
                    Sign Up
                  </Link>
                  <Link
                    to="/signup"
                    className="flex items-center justify-center px-3 py-2 sm:px-4 sm:py-3 md:px-5 md:py-3 bg-purple-950 text-white text-base sm:text-lg font-bold rounded-lg hover:bg-purple-800">
                    Sign In
                  </Link>
                </div>

                <div className="-mr-2 flex items-center md:hidden">
                  <PopoverButton className="rounded-md p-2 inline-flex items-center justify-center focus:outline-none">
                    <span className="sr-only">Open nav menu</span>
                    <FiMenu className="h-8 w-8" aria-hidden="true" />
                  </PopoverButton>
                </div>
              </div>

              <Transition
                show={open}
                as={React.Fragment}
                enter="duration-150 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <PopoverPanel
                  focus
                  static
                  className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-10">
                  {({ close }) => (
                    <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                      <div className="px-5 pt-4 flex items-center justify-between">
                        <div>
                          <img className="h-8 w-auto" src={Logo} alt="Logo" />
                        </div>
                        <div className="-mr-2">
                          <PopoverButton className="rounded-md p-0 inline-flex items-center justify-center focus:outline-none">
                            <span className="sr-only">Close nav menu</span>
                            <FiX className="h-8 w-8" aria-hidden="true" />
                          </PopoverButton>
                        </div>
                      </div>
                      <div className="px-3 pt-2 pb-3 space-y-1">
                        {navigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            onClick={() => {
                              close();
                            }}
                            className="block px-3 py-2 rounded-md text-base font-medium text-black hover:text-gray-500 hover:bg-gray-100">
                            {item.name}
                          </a>
                        ))}
                      </div>
                      <div className="px-2 pt-2 pb-3 space-y-1">
                        <Link
                          to="/"
                          onClick={handleLiveChatClick}
                          className="block px-3 py-2 rounded-md text-base font-medium text-black hover:text-gray-500 hover:bg-gray-100">
                          Live Demo
                        </Link>
                        <Link
                          to="/register"
                          className="block px-3 py-2 rounded-md text-base font-medium text-black hover:text-gray-500 hover:bg-gray-100">
                          Sign Up
                        </Link>
                        <Link
                          to="/signup"
                          className="block px-3 py-2 rounded-md text-base font-medium text-white bg-purple-600 hover:bg-purple-700">
                          Sign In
                        </Link>
                      </div>
                    </div>
                  )}
                </PopoverPanel>
              </Transition>
            </>
          )}
        </Popover>
        <ToastContainer />
      </div>

      <Modal show={showVideo} onClose={handleCloseVideo} videoSrc={yourVideo} />
    </header>
  );
};

export default Header;
