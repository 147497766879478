import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logo from './asset/logoMain.png'; // Replace with your actual logo path
import { useNavigate } from 'react-router-dom';
const Sidebar = ({ setIsSubmitting, userName }) => {
    console.log('username', userName);
  const cloudName = 'detpqzhnq'; // Replace with your Cloudinary cloud_name
  const [imageUrl, setImageUrl] = useState('/profile.jpg'); // Default profile image
  const [uploadedImage, setUploadedImage] = useState();
  const handleLiveChatClick = (e) => {
    e.preventDefault();
    toast.info('Feature is coming soon');
  };
  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (!['image/jpeg', 'image/png', 'image/gif'].includes(selectedFile.type)) {
      toast.warning('Please upload a valid image file (jpeg, png, gif)', {
        autoClose: 3000,
      });
      return;
    }

    setIsSubmitting(true);

    try {
      const formData = new FormData();
      formData.append('file', selectedFile); // Ensure 'file' matches your backend

      const response = await axios.post(
        'https://mentordproductionapi.azurewebsites.net/api/Image/upload',
        formData
      );
      if (response?.status === 200 && response?.data) {
        const imageUrl = response?.data?.url;
        setUploadedImage(imageUrl);
        toast.success('Image uploaded successfully', {
          autoClose: 3000,
        });
      } else {
        toast.error('Failed to upload image. Please try again.', {
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error(
        'Error uploading image:',
        error.response ? error.response.data : error.message
      );
      toast.error('Error uploading image. Please try again.', {
        autoClose: 3000,
      });
    } finally {
      setIsSubmitting(false);
    }
  };
  const navigate = useNavigate();
  const handlenext = (e) => {
    e.preventDefault();
    navigate('/ChatBot');
  };
  return (
    <div className="w-full lg:w-64 bg-gray-100 h-full p-4 shadow-lg transition-transform duration-500 ease-in-out flex flex-col justify-between">
      <div>
        <div className="flex items-center mb-6">
          <a href="/">
            <img src={Logo} alt="Logo" className="w-15 h-15 mr-4" />
          </a>
          <div className="flex flex-col">
            <div className="text-2xl font-bold">CONVERSE AI</div>
            {/* Horizontal line */}
          </div>
        </div>
        <hr className="border-gray-500 border " />
        <div className="flex justify-center p-2 text-center mb-6">
          <label htmlFor="upload-input">
            <div
              className="w-32 h-32 rounded-full border border-gray-300 flex items-center justify-center cursor-pointer"
              style={{
                backgroundImage: `url(${uploadedImage || imageUrl})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}>
              {!uploadedImage && (
                <span className="text-center text-sm">Upload Image</span>
              )}
            </div>
            <p className="mt-2">welcome {userName}</p>
          </label>
          <input
            id="upload-input"
            type="file"
            accept="image/jpeg, image/png, image/gif"
            className="hidden"
            onChange={handleFileChange}
          />
        </div>
        <nav>
          <ul className="space-y-4">
            {/* Navigation links */}
            <li>
              <a
                href="/"
                className="block text-gray-700 hover:text-blue-500 transition-colors duration-300">
                Home
              </a>
            </li>
            <li>
              <a
                className="block text-gray-700 hover:text-blue-500 transition-colors duration-300"
                onClick={handlenext}>
                Converse
              </a>
            </li>
            <li>
              <a
                href="#"
                className="block text-gray-700 hover:text-blue-500 transition-colors duration-300"
                onClick={handleLiveChatClick}>
                Contacts
              </a>
            </li>
            <li>
              <a
                href="#"
                className="block text-gray-700 hover:text-blue-500 transition-colors duration-300"
                onClick={handleLiveChatClick}>
                Automation
              </a>
            </li>
            <li>
              <a
                href="#"
                className="block text-gray-700 hover:text-blue-500 transition-colors duration-300"
                onClick={handleLiveChatClick}>
                Live Chat
              </a>
            </li>
            <li>
              <a
                href="#"
                className="block text-gray-700 hover:text-blue-500 transition-colors duration-300"
                onClick={handleLiveChatClick}>
                Settings
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <div>
        <a
          href="#"
          className="block text-gray-700 hover:text-blue-500 mb-2 transition-colors duration-300"
          onClick={handleLiveChatClick}>
          My Profile
        </a>
        <a
          href="#"
          className="block text-gray-700 hover:text-blue-500 mb-2 transition-colors duration-300"
          onClick={handleLiveChatClick}>
          Help
        </a>
        <a
          href="/"
          className="block text-gray-700 hover:text-blue-500 transition-colors duration-300">
          Logout
        </a>
      </div>
    </div>
  );
};

export default Sidebar;
