import React from 'react';

import { Link } from 'react-router-dom';
import Header from './Header';
import { useNavigate } from 'react-router';
import '../index.css'; // Ensure this path is correct
import whatsapp from './asset/whatsapp.png';
import video from './asset/video.mp4';
import converseAI from './asset/converaa.jpeg';
import customerService from './asset/customer-service.svg';
import conversational from './asset/conversational.JPG';
import Knowledge from './asset/knowledge.png';
import Conversation from './asset/conversation.png';
import whatsappsconversation from './asset/WhatsApp-Cloud-API-Integration.png';
import monitor from './asset/monitoring_3703299.png';
import HowItWorks from './Howitworks';
import BenefitCard from './BenefitCard';
import TestimonialSlider from './TestimonialSlider';
import Footer from './Footer';
const navigate = useNavigate;
const cardContent = [
  {
    title: 'Create Your Converse Assistant',
    description: 'Name with your brand identity, select task and guidelines',
    imgSrc: customerService,
    role: '',
  },
  {
    title: 'Select Conversation Style',
    description: 'Choose language, localization, tone of voice',
    imgSrc: conversational,
    role: '',
  },
  {
    title: 'Add Knowledge',
    description: 'Enter your URL, documents, and FAQs',
    imgSrc: Knowledge,
    role: 'Knowledge Manager',
  },
  {
    title: 'Help Users Start Conversations',
    description: 'Quick prompts to engage users',
    imgSrc: Conversation,
    role: 'Engagement Specialist',
  },
  {
    title: 'Integrate with WhatsApp',
    description: 'Engage users on their favorite messaging app',
    imgSrc: whatsappsconversation,
    role: 'Integration Expert',
  },
  {
    title: 'Monitor and Improve',
    description: 'Analyze interactions and improve responses',
    imgSrc: monitor,
    role: 'Analytics Specialist',
  },
];

const LandingPage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-r from-purple-100 to-white-100">
      <Header />
      <main className="flex flex-col items-center text-white text-center mt-32">
        <div className="flex flex-col md:flex-row items-center w-full max-w-screen-lg mx-auto">
          <div className="flex-1 mb-10 md:mb-0">
            <img src={whatsapp} alt="Phone" className="rounded-lg shadow-lg" />
          </div>
          <div className="flex-1  text-black p-8 md:ml-10 text-center  ">
            <h1 className="text-3xl font-bold md:text-4xl">
              YOUR ALL IN ONE <p className="text-purple-700">CONVERSATIONAL</p>{' '}
              MARKETING PLATFORM To Fuel Your Business Goals On Auto Pilot.
            </h1>
            <p className="mt-6 text-justify font-Montserrat font-serif ">
              Enhance customer experiences with a personalized chatbot that
              answers visitors' questions instantly, trained on your website
              content. Create and deploy your chat and sales buddy across social
              channels to boost growth through support, lead generation, and
              conversions. Enjoy quick onboarding. Our scalable pricing model
              supports 95+ languages. Try risk-free for 14 days and cancel
              anytime.
            </p>
            <div className="flex mt-4 gap-6 justify-center">
              <Link
                to="/register"
                className="bg-purple-800 p-2 sm:p-3 md:p-4 lg:p-5 rounded-lg text-white text-lg sm:text-xl md:text-2xl font-bold text-center transition-transform duration-300 hover:scale-105">
                Try For Free
              </Link>
              <Link
                to="/signup"
                className="bg-white p-2 sm:p-3 md:p-4 lg:p-5 rounded-lg text-purple-700 border-2 font-bold text-lg sm:text-xl md:text-2xl text-center transition-transform duration-300 hover:scale-105">
                Learn More
              </Link>
            </div>
          </div>
        </div>
        <div className="w-full max-w-screen-lg mx-auto mt-10 px-4">
          <div className="relative cards p-8 rounded-lg overflow-hidden bg-white bg-opacity-25 backdrop-blur-lg">
            <div className="text-center text-white w-full">
              <p className="text-xl md:text-2xl font-bold">
                Imagine the possibilities with an expert chatbot available to
                answer your leads and customer questions 24/7/365
              </p>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-10 max-w-screen-lg mx-auto px-4">
          {cardContent.map((card, index) => (
            <div key={index} className="container">
              <div className="cardsm">
                <div className="imgBx">
                  <img src={card.imgSrc} alt={card.title} />
                </div>
                <div className="content">
                  <div className="contentBx">
                    <h3>
                      {card.title}
                      <br />
                      <span>{card.role}</span>
                    </h3>
                    <p className="text-black">{card.description}</p>
                  </div>
                  <ul className="sci">
                    <li style={{ '--i': 1 }}>
                      <a href="#">
                        <i className="fab fa-facebook"></i>
                      </a>
                    </li>
                    <li style={{ '--i': 2 }}>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li style={{ '--i': 3 }}>
                      <a href="#">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="video-container w-full max-w-screen-lg mx-auto mt-10 px-4">
          <img src={converseAI} alt="Phone" className="rounded-lg shadow-lg" />
        </div>

        {/* <div className="video-container w-full max-w-screen-lg mx-auto mt-10 px-4">
          <video
            className="w-full rounded-lg shadow-lg"
            controls
            poster="path-to-your-video-poster.jpg" // Optional: for a poster image
          >
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div> */}

        <div className="w-full max-w-screen-lg mx-auto mt-10 px-4">
          <HowItWorks />
        </div>

        <div className="w-full max-w-screen-lg mx-auto mt-10 px-4">
          <div className="benefits-title">
            <div className="icon-container">
              <div className="icon">
                <i className="fa fa-thumbs-up"></i>
              </div>
            </div>
            <h1 className="text-bold">Benefits</h1>
          </div>
          <BenefitCard />
        </div>
        <div className="w-full max-w-screen-lg mx-auto mt-10 px-4">
          <TestimonialSlider />
        </div>
        <div className="w-full max-w-screen-lg mx-auto mt-10 px-4">
          <Footer />
        </div>
      </main>
    </div>
  );
};

export default LandingPage;
