import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import profileImage from './asset/img.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaAt, FaCommentAlt, FaHeart } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  faCommentAlt,
  faPaperPlane,
  faPlusCircle,
  faBroadcastTower,
} from '@fortawesome/free-solid-svg-icons';
import Tooltip from './utility/Tooltip';
 const handleGenerateTemplate = () => {
   toast.info('ConverseAI Generative Template is Coming soon please.');
 };
const handleLiveChatClick = (e) => {
  e.preventDefault();
  toast.info('See Insight Feature is coming soon');
};
const MainContent = () => {
  return (
    <div className="p-4 md:p-6 transition-opacity duration-500 ease-in-out">
      <header className="mb-5">
        <h1 className="text-2xl md:text-3xl font-bold font-serif">Home</h1>
      </header>
      <hr className="border-gray-500 border " />
      <div className="mt-7">
        <h1 className="text-2xl md:text-3xl font-serif font-bold  ">
          Hello, {}
        </h1>
        <p className="text-gray-600 font-semibold text-xl font-serif">
          1 Connected Channel | 1 Contact |{' '}
          <a href="#" className="text-blue-500" onClick={handleLiveChatClick}>
            See Insights
          </a>
        </p>
      </div>

      <motion.section
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="mt-4 md:mt-6 bg-white p-8 rounded shadow-md">
        <div className="flex flex-col md:flex-row items-center justify-between mb-8 font-serif">
          <div className="flex items-center mb-4 md:mb-0">
            <motion.img
              src={profileImage} // Replace with the path to the user's image
              alt="User"
              className="w-16 h-16 rounded-full mr-4"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2, duration: 0.5 }}
            />
            <motion.h2
              className="text-2xl md:text-md font-bold font-serif text-center md:text-left"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.4, duration: 0.5 }}>
              Instagram Activity Recap
            </motion.h2>
          </div>
          <motion.div
            className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.6, duration: 0.5 }}>
            <Link
              to="#"
              className="text-blue-800 font-semibold font-serif text-xl">
              Yesterday
            </Link>
            <Link to="#" className="text-gray-800 font-bold font-serif text-xl">
              Last 7 Days
            </Link>
          </motion.div>
        </div>
        <motion.p
          className="text-gray-600 mb-4 font-bold font-serif text-xl text-center md:text-left"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.8, duration: 0.5 }}>
          We’re currently gathering your activity. Please check back tomorrow!
        </motion.p>
        <motion.div
          className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 text-center"
          initial="hidden"
          animate="visible"
          variants={{
            hidden: {
              opacity: 0,
              scale: 0.8,
            },
            visible: {
              opacity: 1,
              scale: 1,
              transition: {
                delayChildren: 1,
                staggerChildren: 0.2,
              },
            },
          }}>
          {[
            'Comments',
            'Story Replies',
            'DM Conversations',
            'Story Mentions',
          ].map((activity, index) => (
            <motion.div
              key={index}
              className="activity-item"
              variants={{
                hidden: { opacity: 0, y: 20 },
                visible: { opacity: 1, y: 0 },
              }}>
              <p className="font-semibold mb-10 text-lg">{activity}</p>
              <div className="text-center">
                <p className="text-2xl font-normal">-</p>
              </div>
              <p className="text-xl md:text-xl font-semibold font-serif">
                0 Automatized 0%
              </p>
            </motion.div>
          ))}
        </motion.div>
      </motion.section>
      <h2 className="text-2xl md:text-md mt-10 font-semibold font-serif">
        Where do most of your Instagram leads come from?
      </h2>
      <section className="mt-4 md:mt-6 bg-white p-4 rounded shadow-md transition-transform duration-500 ease-in-out">
        <p className="font-serif text-xl">
          Your answers will help us generate marketing solutions tailored for
          your context.
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-6 ">
          <Tooltip text="Click to select this option">
            <label className="flex justify-between items-center p-4 border border-gray-500 rounded-lg transition-transform duration-300 hover:scale-105 cursor-pointer w-full mb-6">
              <div className="flex items-center">
                <FontAwesomeIcon icon={faCommentAlt} className="mr-2" />
                <span className="text-lg">Comments</span>
              </div>
              <input type="radio" name="lead-source" className="ml-2" />
            </label>
          </Tooltip>
          <Tooltip text="Click to select this option">
            <label className="flex justify-between items-center p-4 border border-gray-500 rounded-lg transition-transform duration-300 hover:scale-105 cursor-pointer w-full ">
              <div className="flex items-center">
                <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                <span className="text-lg">Stories</span>
              </div>
              <input type="radio" name="lead-source" className="ml-2" />
            </label>
          </Tooltip>
          <Tooltip text="Click to select this option">
            <label className="flex justify-between items-center p-4 border border-gray-500 rounded-lg transition-transform duration-300 hover:scale-105 cursor-pointer w-full">
              <div className="flex items-center">
                <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
                <span className="text-lg">Direct Message</span>
              </div>
              <input type="radio" name="lead-source" className="ml-2" />
            </label>
          </Tooltip>
          <Tooltip text="Click to select this option">
            <label className="flex justify-between items-center p-4 border border-gray-500 rounded-lg transition-transform duration-300 hover:scale-105 cursor-pointer w-full">
              <div className="flex items-center">
                <FontAwesomeIcon icon={faBroadcastTower} className="mr-2" />
                <span className="text-lg">Lives</span>
              </div>
              <input type="radio" name="lead-source" className="ml-2" />
            </label>
          </Tooltip>
        </div>
        <div className="flex justify-end mt-4">
          <button
            className="bg-blue-500 text-white px-8 py-3 rounded-lg transition-transform duration-300 hover:scale-105"
            onClick={handleGenerateTemplate}>
            Generate Template
          </button>
        </div>
      </section>
      <p className="text-2xl md:text-md mt-10 font-semibold font-serif">
        Automate your routine once and for all
      </p>
      <section className="mt-4 md:mt-6 bg-white p-4 rounded shadow-md transition-transform duration-500 ease-in-out">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
          <Tooltip text="Automatically send a message when a person mentions you in.">
            <div className="bg-blue-200 p-4 rounded-lg flex flex-col justify-between">
              <div className="flex items-center justify-center w-12 h-12 rounded-full bg-white">
                <FaAt className="text-blue-500" />
              </div>
              <h3 className="mt-4 text-xl font-semibold">
                Story mention reply
              </h3>
              <p className="mt-2 text-gray-600">
                Automatically send a message when a person mentions you in.
              </p>
            </div>
          </Tooltip>
          <Tooltip text="Easily automate replies to most frequently asked questions about.">
            <div className="bg-yellow-200 p-4 rounded-lg flex flex-col justify-between">
              <div className="flex items-center justify-center w-12 h-12 rounded-full bg-white">
                <FaCommentAlt className="text-yellow-500" />
              </div>
              <h3 className="mt-4 text-xl font-semibold">FAQ</h3>
              <p className="mt-2 text-gray-600">
                Easily automate replies to most frequently asked questions
                about.
              </p>
            </div>
          </Tooltip>
          <Tooltip text="Automate sending a message every time someone reacts to.">
            <div className="bg-purple-200 p-4 rounded-lg flex flex-col justify-between">
              <div className="flex items-center justify-center w-12 h-12 rounded-full bg-white">
                <FaHeart className="text-purple-500" />
              </div>
              <h3 className="mt-4 text-xl font-semibold">
                Story reaction reply
              </h3>
              <p className="mt-2 text-gray-600">
                Automate sending a message every time someone reacts to.
              </p>
            </div>
          </Tooltip>
          <ToastContainer />
        </div>
      </section>
    </div>
  );
};

export default MainContent;
