import React, { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import Sidebar from './Sidebar';
import MainContent from './MainContent';
import Spinner from './utility/spinner';
import { useLocation } from 'react-router-dom';

const Dashboard = () => {
    const location = useLocation();
    const { userName } = location.state || {};
     console.log('username', userName);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex h-screen overflow-hidden relative">
      {isSubmitting && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <Spinner />
        </div>
      )}
      <button
        onClick={toggleSidebar}
        className="absolute top-4 left-4 z-20 p-2 bg-blue-500 text-white rounded-md lg:hidden">
        {isSidebarOpen ? <FaTimes /> : <FaBars />}
      </button>
      <div
        className={`fixed inset-0 z-10 bg-black opacity-50 lg:hidden transition-opacity duration-300 ${
          isSidebarOpen ? 'block' : 'hidden'
        }`}
        onClick={toggleSidebar}></div>
      <div
        className={`fixed inset-y-0 left-0 z-20 w-64 bg-gray-100 p-4 shadow-lg transition-transform duration-300 ease-in-out lg:static lg:translate-x-0 ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        }`}>
        <Sidebar setIsSubmitting={setIsSubmitting} userName={userName} />
      </div>
      <div className="flex-1 overflow-auto p-4 md:p-6">
        <MainContent />
      </div>
    </div>
  );
};

export default Dashboard;
