import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

import { FcGoogle } from 'react-icons/fc';
import { FaFacebookF, FaApple } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { MdEmail, MdLock } from 'react-icons/md';
import Spinner from './utility/spinner';
import HeloWelcomeBack from './asset/Group33.png';
import Logo from './asset/logoMain.png'; // Replace with your actual logo path
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
function SignIn() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
  });

 const formik = useFormik({
   initialValues: {
     username: '',
     password: '',
   },
   validationSchema: validationSchema,
   onSubmit: async (values) => {
     setIsSubmitting(true);
     try {
       const response = await axios.post(
         'https://usercredentialsopenapi.azurewebsites.net/api/Account/login',
         values
       );
       const token = response?.data?.token?.token;
       const userName = response?.data?.userName;

       if (token) {
         localStorage.setItem('token', token);
         navigate('/dashboard', { state: { userName } });
         toast.success('Login successful');
       }
     } catch (error) {
       if (axios.isAxiosError(error)) {
         if (error.response) {
           const errorMessage =
             error.response.data.error || 'Login failed. Please try again.';
           toast.error(errorMessage);
         } else if (error.request) {
           toast.error('Network error. Please try again.');
         } else {
           toast.error('An unexpected error occurred. Please try again later.');
         }
       }
     } finally {
       setIsSubmitting(false);
     }
   },
 });

  const handleGoogleLoginSuccess = async (response) => {
    setIsSubmitting(true);
    navigate('/dashboard');
    toast.success('Login successful');
    try {
      const { tokenId } = response;
      const res = await axios.post(
        'https://mentordproductionapi.azurewebsites.net/api/Account/google-signin',
        { token: tokenId }
      );

      const token = res?.data?.token;
      if (token) {
        localStorage.setItem('token', token);
      }
    } catch (error) {
      toast.error('Google login failed. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleGoogleLoginFailure = (error) => {
    // toast.error('Google login failed. Please try again.');
    console.error(error);
  };

  return (
    <GoogleOAuthProvider clientId="408673704700-q1rmf0bhb4dof5jcgvk5o9jn53r2d1ta.apps.googleusercontent.com">
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <motion.div
          className="flex flex-col md:flex-row w-full max-w-5xl bg-white rounded-lg shadow-xl overflow-hidden"
          initial={{ x: '-100%' }}
          animate={{ x: 0 }}
          transition={{ type: 'spring', stiffness: 100 }}>
          <div className="md:w-1/2 p-8 w-full relative">
            <div className="flex flex-col items-left">
              <a href="/">
                <img src={Logo} alt="Logo" className="mb-6 mr-4 w-16 h-16" />
              </a>
              <motion.h2
                className="text-3xl font-bold mb-6"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}>
                Sign in
              </motion.h2>
            </div>

            <p className="text-black mb-10 text-xl font-popins mt-10">
              If you don't have an account register <br />
              You can{' '}
              <Link to="/register" className="text-blue-600">
                Register here!
              </Link>
            </p>
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-3 relative">
                <label
                  htmlFor="username"
                  className="block mb-4 text-xl font-bold text-black">
                  Username
                </label>
                <div className="flex items-center border rounded-lg shadow-md focus-within:ring-indigo-500 focus-within:border-indigo-500">
                  <MdEmail className="text-gray-500 ml-3" />
                  <input
                    type="text" // Corrected type to "text"
                    id="username"
                    name="username"
                    placeholder="Enter your username address"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`w-full px-4 py-3 border-none focus:outline-none ${
                      formik.touched.username &&
                      formik.errors.username &&
                      'border-red-500'
                    }`}
                  />
                </div>
                {formik?.touched?.username && formik.errors.username && (
                  <p className="text-red-700 text-smd">
                    {formik.errors.username}
                  </p>
                )}
              </div>
              <div className="mb-4 relative">
                <label
                  htmlFor="password"
                  className="block mb-4 text-xl font-bold text-black">
                  Password
                </label>
                <div className="flex items-center border rounded-lg shadow-md focus-within:ring-indigo-500 focus-within:border-indigo-500">
                  <MdLock className="text-gray-500 ml-3" />
                  <input
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Enter your Password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`w-full px-4 py-3 border-none focus:outline-none ${
                      formik.touched.password &&
                      formik.errors.password &&
                      'border-red-500'
                    }`}
                  />
                </div>
                {formik.touched.password && formik.errors.password && (
                  <p className="text-red-700 text-md">
                    {formik.errors.password}
                  </p>
                )}
              </div>
              <div className="flex items-center justify-between mb-4">
                <label className="flex items-center text-lg">
                  <input type="checkbox" className="form-checkbox" />
                  <span className="ml-2 text-black">Remember me</span>
                </label>
                <a href="#" className="text-lg text-black hover:underline">
                  Forgot Password?
                </a>
              </div>
              <button
                type="submit"
                className="w-full py-4 mb-4 text-white text-lg bg-blue-700 rounded-full hover:bg-blue-500"
                disabled={isSubmitting}>
                {isSubmitting ? 'Please wait...' : 'Login'}
              </button>
              <div className="text-center text-black text-lg mb-4">
                or continue with
              </div>
              <div className="flex justify-center space-x-4 mb-4">
                <button className="flex items-center justify-center w-10 h-10 border rounded-full hover:bg-gray-100">
                  <FaFacebookF className="text-blue-600" />
                </button>
                <button className="flex items-center justify-center w-10 h-10 border rounded-full hover:bg-gray-100">
                  <FaApple className="text-black" />
                </button>
                <GoogleLogin
                  onSuccess={handleGoogleLoginSuccess}
                  onFailure={handleGoogleLoginFailure}
                  render={(renderProps) => (
                    <button
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                      className="flex items-center gap-4 w-full h-full">
                      <span className="pl-4">
                        <FcGoogle size={20} />
                      </span>
                      <p>Sign in with Google</p>
                    </button>
                  )}
                />
              </div>
            </form>
          </div>
          <motion.div
            className="hidden md:flex md:w-1/2 items-center justify-center relative"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}>
            <img
              src={HeloWelcomeBack}
              alt="Illustration"
              className="absolute top-0 left-0 w-full h-full"
            />
          </motion.div>
        </motion.div>

        {isSubmitting && (
          <div className="fixed top-0 left-0 z-50 w-full h-full flex justify-center items-center bg-gray-400 bg-opacity-75">
            <div className="flex flex-col items-center">
              <Spinner />
              <p className="text-black">Logging in...</p>
            </div>
          </div>
        )}

        <ToastContainer />
      </div>
    </GoogleOAuthProvider>
  );
}

export default SignIn;
