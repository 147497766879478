import React from 'react';
import '../index.css'; // Ensure this path is correct
import { FaStar } from 'react-icons/fa';

const HowItWorks = () => {
  const steps = [
    {
      description: 'Get started with a simple registration process.',
    },
    {
      description:
        'Connect Convers AI with your social media and messaging apps.',
    },
    {
      description:
        'Use our intuitive dashboard to manage interactions, track leads, and monitor performance.',
    },
  ];

  return (
    <div className="how-it-works md:grid-cols-3 gap-4 mt-10 max-w-screen-lg mx-auto px-4">
      <div className=" mx-auto text-center">
        <h2 className="text-3xl font-bold lg:mr-[700px] mb-10 flex ">
          <FaStar className="mr-10 text-black" /> How It Works
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {steps.map((step, index) => (
            <div
              key={index}
              className="how-it-works-card border-2 border-purple-600 rounded-lg p-6 flex flex-col items-center">
              <FaStar className="text-black mb-4" />
              <p>{step.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
