import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
  faUser,
  faLock,
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import logo from './asset/logoMain.png'; // Ensure you have the logo image in the specified path
import signupImage from './asset/signupk.JPG'; // Ensure you have the signup image in the specified path
import Spinner from './utility/spinner'; // Import the updated Spinner component

function SignUp({ dispatch }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required('Please enter a username.'),
    email: Yup.string()
      .email('Please enter a valid email address.')
      .required('Email is required.'),
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters long.')
      .required('Password is required.'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords do not match.')
      .required('Please confirm your password.'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      username: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const response = await axios.post(
          'https://usercredentialsopenapi.azurewebsites.net/api/Account/register',
          
          values
        );

        const { status, data } = response;

        if (status === 200) {
          toast.success(data.message);
          setTimeout(() => {
            navigate('/signup');
          }, 3000); 
        } else {
          handleApiErrors(data);
        }
      } catch (error) {
        let errorMessage = 'An error occurred. Please try again.';
        if (
          error.response &&
          error.response.data &&
          error.response.data.title
        ) {
          errorMessage = error.response.data.title;
        }
        toast.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    },
  });

  const handleApiErrors = (data) => {
    if (data.errors) {
      Object.keys(data.errors).forEach((key) => {
        formik.setFieldError(key, data.errors[key][0]);
      });
    } else {
      toast.error(data.title || 'Registration failed.');
    }
  };

  return (
    <div className="flex flex-col lg:flex-row h-screen relative">
      {isLoading && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-75 z-50">
          <Spinner />
        </div>
      )}

      <motion.div
        className="flex items-center justify-center w-full lg:w-1/2 bg-gray-100"
        initial={{ x: '-100%' }}
        animate={{ x: 0 }}
        transition={{ type: 'spring', stiffness: 100 }}>
        <div className="w-full max-w-md px-8 py-10 bg-white rounded-lg shadow-xl relative">
          <div className="flex flex-col items-left mb-5">
            <a href="/">
              <img src={logo} alt="Logo" className="w-16 h-16 mr-4 mb-6" />
            </a>

            <motion.h2
              className="text-3xl font-bold text-black"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}>
              Sign up
            </motion.h2>
          </div>
          <p className="text-black text-xl font-popins mb-10 mt-10">
            If you already have an account register <br />
            You can{' '}
            <Link to="/signup" className="text-blue-600">
              Login here!
            </Link>
          </p>
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-2">
              <label
                htmlFor="email"
                className="block mb-4 text-xl font-bold text-black">
                Email
              </label>
              <div className="flex items-center border rounded-lg shadow-md">
                <div className="px-4 py-4 bg-gray-200 rounded-l-md">
                  <FontAwesomeIcon icon={faEnvelope} />
                </div>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`w-full px-4 py-3 border-none focus:outline-none ${
                    formik.touched.email && formik.errors.email
                      ? 'border-red-500'
                      : ''
                  } rounded-r-md focus:ring-blue-500`}
                />
              </div>
              {formik.touched.email && formik.errors.email && (
                <p className="mt-1 text-md text-red-700">
                  {formik.errors.email}
                </p>
              )}
            </div>
            <div className="mb-2">
              <label
                htmlFor="username"
                className="block mb-4 text-xl font-bold text-black">
                Username
              </label>
              <div className="flex items-center border rounded-lg shadow-md">
                <div className="px-4 py-4 bg-gray-200 rounded-l-md">
                  <FontAwesomeIcon icon={faUser} />
                </div>
                <input
                  type="text"
                  id="username"
                  name="username"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`w-full px-4 py-3 border-none focus:outline-none ${
                    formik.touched.username && formik.errors.username
                      ? 'border-red-500'
                      : ''
                  } rounded-r-md focus:ring-blue-500`}
                />
              </div>
              {formik.touched.username && formik.errors.username && (
                <p className="mt-1 text-md text-red-700">
                  {formik.errors.username}
                </p>
              )}
            </div>
            <div className="mb-2">
              <label
                htmlFor="password"
                className="block mb-4 text-xl font-bold text-black">
                Password
              </label>
              <div className="flex items-center border rounded-lg shadow-md">
                <div className="px-4 py-4 bg-gray-200 rounded-l-md">
                  <FontAwesomeIcon icon={faLock} />
                </div>
                <input
                  type={isPasswordVisible ? 'text' : 'password'}
                  id="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`w-full px-4 py-3 border-none focus:outline-none ${
                    formik.touched.password && formik.errors.password
                      ? 'border-red-500'
                      : ''
                  } rounded-r-md focus:ring-blue-500`}
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="px-4 py-4 bg-gray-200 rounded-r-md focus:outline-none">
                  <FontAwesomeIcon
                    icon={isPasswordVisible ? faEyeSlash : faEye}
                  />
                </button>
              </div>
              {formik.touched.password && formik.errors.password && (
                <p className="mt-1 text-md text-red-700">
                  {formik.errors.password}
                </p>
              )}
            </div>
            <div className="mb-2">
              <label
                htmlFor="confirmPassword"
                className="block mb-4 text-xl font-bold text-black">
                Confirm Password
              </label>
              <div className="flex items-center border rounded-lg shadow-md">
                <div className="px-4 py-4 bg-gray-200 rounded-l-md">
                  <FontAwesomeIcon icon={faLock} />
                </div>
                <input
                  type={isConfirmPasswordVisible ? 'text' : 'password'}
                  id="confirmPassword"
                  name="confirmPassword"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`w-full px-4 py-3 border-none focus:outline-none ${
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                      ? 'border-red-500'
                      : ''
                  } rounded-r-md focus:ring-blue-500`}
                />
                <button
                  type="button"
                  onClick={toggleConfirmPasswordVisibility}
                  className="px-4 py-4 bg-gray-200 rounded-r-md focus:outline-none">
                  <FontAwesomeIcon
                    icon={isConfirmPasswordVisible ? faEyeSlash : faEye}
                  />
                </button>
              </div>
              {formik.touched.confirmPassword &&
                formik.errors.confirmPassword && (
                  <p className="mt-1 text-md text-red-700">
                    {formik.errors.confirmPassword}
                  </p>
                )}
            </div>
            <button
              type="submit"
              className={`w-full px-4 py-4 text-white text-2xl rounded-full focus:outline-none ${
                !formik.isValid || isLoading
                  ? 'bg-gray-400 cursor-not-allowed'
                  : 'bg-blue-700 hover:bg-blue-500'
              }`}
              disabled={!formik.isValid || isLoading}>
              {'Register'}
            </button>
          </form>
          <p className="mt-4 text-center text-lg text-black">
            If you already have an account,{' '}
            <Link to="/signup" className="text-blue-600 hover:underline">
              Login here!
            </Link>
          </p>
        </div>
      </motion.div>
      <motion.div
        className="hidden lg:flex items-center justify-center w-full lg:w-1/2 bg-blue-900"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}>
        <img
          src={signupImage}
          alt="Sign Up"
          className="w-full h-full object-cover"
        />
      </motion.div>
      <ToastContainer />
    </div>
  );
}

export default connect()(SignUp);
