import React from 'react';

import Logo from '../asset/logoMain.png';

const Spinner = () => {
  return (
    <div className="relative">
      <div
        className="inline-block h-20 w-20 animate-spin rounded-e-3xl border-10 border-solid border-current border-r-transparent align-middle motion-reduce:animate-spin-[1.9s_linear_infinite]"
        role="status">
        <img
          src={Logo}
          alt="logo"
          className="w-16 h-16 rounded-s-3xl inset-0 m-auto"
        />
      
      </div>
    </div>
  );
};

export default Spinner;

