import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const Modal = ({ show, onClose, videoSrc }) => {
  if (!show) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
      onClick={onClose}>
      <div
        className="relative w-full max-w-3xl mx-auto bg-white rounded-lg overflow-hidden"
        onClick={(e) => e.stopPropagation()}>
        <button
          className="absolute top-2 right-2 text-white text-2xl"
          onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <div className="w-full h-full relative p-4">
          <video
            className="w-full h-full max-h-[80vh] rounded-lg"
            controls
            autoPlay>
            <source src={videoSrc} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
};

export default Modal;
